.PayoutDetails--TableRow {
  height: 68.8px;
  &:first-child {
    border-top-left-radius: 10px;
  }

  .Table--ID {
    p {
      color: #000;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .Table--Amount,
  .Table--DateTime {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .debit {
      color: #d2293d;
    }
  }

  .Table--Amount {
    div {
      p {
        color: #000;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      span {
        color: #000;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .Table--DateTime {
    div {
      p {
        color: #000;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        color: #000;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .Table--Remarks {
    p {
      color: #000;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
