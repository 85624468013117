.Widget{
    .Widget--Header{
        h6 {
            color: #000;
            font-size: 22px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
          }
          a {
            color: #1b8bcf;
            text-align: right;
            font-size: 15px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
          }
    }
}