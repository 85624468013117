.ExpertWorksheet--Dashboard {
  .ExpertWorksheet--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}

.ExpertAppointmentDashboard--Options {
  justify-content: space-between;

  .Options {
    .Option--Item {
      padding: 11px 25px;
      border-radius: 30px;
      border: 1px solid #d9d9d9;
      background: #f5f5f5;
      color: #6a6c6a;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .SelectedItem {
      background: #153300;
      color: #fff;
    }
  }
}

.NewWorksheet--Grid {
  .NewWorksheet--Left {
    .Left--Container {
      span {
        color: #1b8bcf;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }
      h6 {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }
      .css-1vgzwi7-MuiFormControl-root{
        width: 100%;
      }
      .MuiFormGroup-root {
        display: grid;
        grid-template-columns: 1fr 1fr;
        span {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }
    .NewSection {
      border: 2px dashed #1b8bcf;
    }
    .CustomQuestion {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;
      fieldset {
        margin: 0 !important;
      }
    }
    .Left--Data {
      & > p {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
      & > div {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }

  .NewWorksheet--Right {
    & > div {
      & > span {
        color: #d2293d;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }
      & > p {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
      .Actions--Container {
        button {
          flex: 1%;
          display: flex;
          height: 45px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 5px;
          background: #f5f5f5;
          color: #414141;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
        }
        .SaveButton {
          background: #1b8bcf;
          color: #fff;
        }
      }
      .dropzone {
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding: 10px;
          border-radius: 10px;
          border: 1px dashed #929292;
          margin-top: 15px;
        }
      }
    }
  }
}

.ViewWorksheet--Table {
  .Table--Profile {
    h6 {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .Table--Progress {
    p {
      border-radius: 20px;
      background: rgba(157, 234, 102, 0.2);
      padding: 5px 10px;
      color: #55a51d;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      width: fit-content;
    }
  }
}

.NewWorksheet--UserDetails {
  .User--Details {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        color: #6a6c6a;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      p {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .complete {
        display: inline-flex;
        padding: 3px 14px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        background: rgba(157, 234, 102, 0.2);
        color: #55a51d;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
