.Reschedule--Dialog {
  .Content--Header {
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    padding: 15px;
    img {
      width: 45px;
      height: 45px;
    }
    h6 {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    span {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.21px;
    }
  }

  h4 {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 12px;
  }

  .SlotTiming {
    margin-top: 20px;
    align-items: center;
    p {
      color: #929292;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .SelectedSlot {
      font-weight: 600;
      color: #1b8bcf;
    }
  }
  .Timings {
    margin-top: 20px;
    .MuiGrid-item {
      
      text-align: center;
      display: flex;
      padding: 10px 0px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
      p {
        color: #929292;
        font-size: 14px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.21px;
      }
    }

    .AvailableSlot {
      cursor: pointer;
      background-color: #fff;
      p {
        color: #000;
      }
    }

    .SelectedSlot {
      border: 1px solid #1b8bcf;
      outline-width: 4px !important;
      outline-style: solid !important;
      outline-color: #1b8bcf33 !important;
      p {
        color: #1b8bcf;
        font-weight: 600;
      }
    }
  }
}
