.SettingsDashboard {
  .ExpertSettingsDashboard--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}

.ExpertSettingsDashboard--Options {
  justify-content: space-between;

  .Options {
    .Option--Item {
      padding: 11px 25px;
      border-radius: 30px;
      border: 1px solid #d9d9d9;
      background: #f5f5f5;
      color: #6a6c6a;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .SelectedItem {
      background: #153300;
      color: #fff;
    }
  }
}
.ExpertSettingsDashboard--Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  h4 {
    color: #000;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  h6 {
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-top: 20px;
  }

  .css-1xhypcz-MuiStack-root {
    padding-top: 0 !important;
  }
}

.UploadedFiles--Section {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .UploadedItem {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 10px;
      border: 2px solid #d9d9d9;
      padding: 2px;
      aspect-ratio: 1/1;
    }

    p {
      margin-top: 5px;
      color: #21231e;
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.Content--Section {
  & > span {
    color: #000;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}
