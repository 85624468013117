@import "./styles/dashboard/NavigationRail.scss";

@import "./styles/dashboard/Widgets.scss";

@import "./styles/dashboard/Notifications.scss";
@import "./styles/dashboard/ExpertDashboard.scss";
@import "./styles/dashboard/Inbox.scss";
@import "./styles/dashboard/Calendar.scss";

@import "./styles/dashboard/ExpertAppointment.scss";
@import "./styles/dashboard/ExpertClientDashboard.scss";
@import "./styles/dashboard/ExpertScheduleDashboard.scss";
@import "./styles/dashboard/ExpertWorksheetDashboard.scss";
@import "./styles/dashboard/ExpertArticlesDashboard.scss";
@import "./styles/dashboard/ExpertPosts.scss";
@import "./styles/dashboard/ExpertNotifications.scss";
@import "./styles/dashboard/ExpertHelpCenter.scss";
@import "./styles/dashboard/ExpertSettings.scss";
@import "./styles/dashboard/ExpertChatDashboard.scss";

/*Cards*/
@import "./styles/cards/UpcomingAppointmentCard.scss";
@import "./styles/cards/NotificationRowCard.scss";
@import "./styles/cards/InboxRowCard.scss";
@import "./styles/cards/AppointmentCard.scss";
@import "./styles/cards/WorksheetCard.scss";
@import "./styles/cards/ArticleCard.scss";
@import "./styles/cards/PayoutWidget.scss";

//Tables
@import "./styles/tables/ClientDetailsTable.scss";
@import "./styles/tables/TicketDetailsTable.scss";
@import "./styles/tables/PayoutDetailsTableRow.scss";

//Dialogs
@import "./styles/dialogs/RescheduleDialog.scss";
@import "./styles/dialogs/NotesDialog.scss";
@import "./styles/dialogs/PrescriptionDialog.scss";
@import "./styles/dialogs/CancelDialog.scss";
@import "./styles/dialogs/AddAppointmentDialog.scss";

//Common
@import "./styles/common/CTA.scss";
@import "./styles/common/Background.scss";
@import "./styles/common/Icons.scss";
@import "./styles/common/Dropdowns.scss";
@import "./styles/common/Dialog.scss";
@import "./styles/common/ComingSoon.scss";

//Animation
@import "./styles/common/Animation.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  //overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiOutlinedInput-notchedOutline{
  border-radius: 10px !important;
}