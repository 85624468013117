.ScheduleDashboard {
  .ScheduleDashboard--Header {
    justify-content: space-between;
    align-items: center;

    h2 {
      color: #000;
      font-size: 22px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .ScheduleDashboard--Content {
    flex-direction: column;
  }
}

.PopulateSection--Inputs {
  & > div {
    flex: 1;
  }
}

.PopulateSection {
  h4 {
    color: #000;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
  button {
    display: block;
    margin-left: auto;
    //margin-right: auto;
  }
}

.ManualSection {
  h4 {
    color: #000;
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  .GoToDate {
    width: 118px;
  }

  .Selector--Header {
    h4 {
      color: #000;
      font-size: 22px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .ManualSection--Selector {
    margin-top: 20px;
    & > div {
      height: calc(100vh - 280px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bbb7b7; /* Color of the scrollbar handle */
        border-radius: 4px; /* Border radius of the scrollbar handle */
       // margin: 10px;
      }
    }
  }
}

.DateSelector--Container {
  button {
    padding: 0 !important;
    min-width: 0;
    display: flex;
    width: 32px;
    height: 55px;
    padding: 8px 41px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    background: #f3f3f3;
    color: #1b8bcf;
  }
  th {
    padding: 0;
  }
  .DateSelector--Dates {
    flex: 1;
    justify-content: space-between;
  }

  .DateSelector--Item {
    height: 55px;
    display: flex;
    flex-direction: column;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #fff;

    p {
      color: #414141;
      text-align: center;
      font-size: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    span {
      color: #414141;
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.ManualDateSelector--Table {
  th,
  td {
    border-bottom: 0 !important;
  }
  thead {
    th {
      &:first-child {
        color: #000;
        font-size: 22px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-align: center;
      }
    }
  }
  tbody {
    margin-top: 15px;
    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 10px;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 10px;
          }
        }
      }
      td {
        //padding: 0 10px;
        &:first-child {
          background-color: #f3f3f3;

          div {
            background: #fff;
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            color: #414141;
            font-size: 14px;
          }
        }
      }
    }

    .MuiCheckbox-root {
      svg {
        color: #55a51d !important;
      }
    }

    .Mui-disabled{
      svg {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
}
.redirectToAppointmentsCTA {
  &:hover {
    color: #fff;
    background-color: #1565c07a;
  }
}
