.MediaCard--Container {
  margin: -12px -15px;
  border-radius: 15px;
}
.MediaCard--Container .MediaCard {
  border-radius: 15px;
}
.MediaCard--Container .MediaCard .card-img-container {
  position: relative;
  cursor: pointer;
}
.MediaCard--Container .MediaCard .card-img-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(38deg, rgba(0, 0, 0, 0.7092086835) 0%, rgba(255, 255, 255, 0) 86%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}
.MediaCard--Container .MediaCard .card-img-container svg {
  width: 29px;
  height: 29px;
  fill: #541CF5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  border-radius: 50%;
}
.MediaCard--Container .MediaCard .card-img-top {
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 15px;
}
.MediaCard--Container .MediaCard button {
  background-color: #c4e5f9;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-weight: 500;
}

.MediaCard--modal .modal-dialog {
  max-width: 756px;
  width: 95%;
  right: 50% !important;
  transform: translate(50%, -50%) !important;
}
.MediaCard--modal .modal-dialog .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 756px;
  aspect-ratio: 16/9;
  height: auto;
}
.MediaCard--modal .modal-dialog .modal-body .VideoIframe {
  width: 100%;
  height: 100%;
}
.MediaCard--modal .modal-dialog .modal-body .VideoIframe iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.MediaCard--modal .modal-dialog .modal-body svg {
  width: 80px;
  padding: 15px;
  background: #f1f7ff;
  border-radius: 50%;
  color: #3c5566;
  margin: 48px auto;
}
.MediaCard--modal .modal-dialog .modal-body video {
  width: 100%;
}
.MediaCard--modal .modal-dialog .modal-body audio {
  max-width: 400px;
  width: 90%;
}/*# sourceMappingURL=MediaCardResponse.css.map */