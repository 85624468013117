.AppointmentCard {
  .MuiCardContent-root {
    .Card--Top {
      align-items: center;
      img {
        aspect-ratio: 1/1;
        height: 45px;
        width: 45px;
      }

      h6 {
        color: #000;
        font-size: 16px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }
      p {
        color: #6a6c6a;
        font-size: 13px;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      svg {
        width: 18px;
        height: 18px;
        fill: #d2293d;
        margin-left: auto;
        margin-bottom: auto;
      }
    }

    .Card--Timings {
      align-items: center;
      gap: 15px;
      margin-top: 15px;

      div {
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
          width: 21px;
          height: 21px;
          fill: #1b8bcf;
        }
        p {
          color: #21231e;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
        }
      }
    }
  }

  .Card--Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: #f5f5f5;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 20px;
      color: #414141;
      font-size: 14px;
      flex: 1;
      svg {
        width: 16px;
        height: 16px;
      }
      p {
        line-height: 130%;
        text-transform: capitalize;
      }
      &:nth-child(3) {
        padding: 8px;
        min-width: 0;
        aspect-ratio: 1/1;
        flex: 0.25;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.Appointment--Cancelled{
  background-color: #fceeee !important;
  height: 100%;
  .Card--Actions{
    button{
      background-color: #FFF;
    }
  }
}